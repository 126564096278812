body {
    background-color: #fefefe;
}

span .coma:last-child {
    display: none;
}

.MuiTableRow-root:hover {
    background-color: rgba(0, 0, 0, .075);
}

.icono-omnix {
    display: block;
    position: absolute;
    height: 44px;
    left: 4px;
    top: 18px;
}

@media (max-width: 780px) {
    .icono-omnix {
        left: auto;
        right: 12px;
    }
}

.icono-omnix img {
    position: relative;
    height: 100%;
    margin: 0 auto;
    display: block;
    padding: 10px 0;
}

.hideLogo .icono-omnix {
    display: none;
}

.error-container {
    text-align: center;
    font-size: 120px;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
}

.error-container>span {
    display: inline-block;
    line-height: 0.7;
    position: relative;
    color: #FD0049;
}

.error-container>span>span {
    display: inline-block;
    position: relative;
}

.error-container>span:nth-of-type(1) {
    perspective: 1000px;
    perspective-origin: 500% 50%;
    color: #FD0049;
}

.error-container>span:nth-of-type(1)>span {
    transform-origin: 50% 100% 0px;
    transform: rotateX(0);
    animation: easyoutelastic 8s infinite;
}

.error-container>span:nth-of-type(3) {
    perspective: none;
    perspective-origin: 50% 50%;
    color: #FD0049;
}

.error-container>span:nth-of-type(3)>span {
    transform-origin: 100% 100% 0px;
    transform: rotate(0deg);
    animation: rotatedrop 8s infinite;
}

th,
tbody tr td a,
nav.MuiList-root .MuiTypography-body1 {
    text-transform: capitalize;
}

@keyframes easyoutelastic {
    0% {
        transform: rotateX(0);
    }
    9% {
        transform: rotateX(210deg);
    }
    13% {
        transform: rotateX(150deg);
    }
    16% {
        transform: rotateX(200deg);
    }
    18% {
        transform: rotateX(170deg);
    }
    20% {
        transform: rotateX(180deg);
    }
    60% {
        transform: rotateX(180deg);
    }
    80% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(0);
    }
}

@keyframes rotatedrop {
    0% {
        transform: rotate(0);
    }
    10% {
        transform: rotate(30deg);
    }
    15% {
        transform: rotate(90deg);
    }
    70% {
        transform: rotate(90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotateX(0);
    }
}